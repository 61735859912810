@import '../../../../../shared/styles/variables.less';
.compliance-documents {
	&__types {
		width: 100%;
		display: flex;
		height: 50px;
		line-height: 50px;
		cursor: pointer;

		> div {
			width: 50%;
			text-align: center;
		}
		.active-type {
			color: @links-color;
			border-bottom: 2px solid @links-color;
			> span {
				margin-left: 5px;
				padding: 2px 6px;
				border-radius: 9px;
				color: white;
				background-color: @links-color;
				font-size: 0.9em;
			}
		}
		.inactive-type {
			> span {
				margin-left: 5px;
				padding: 3px 7px;
				border-radius: 10px;
				background-color: #f0f0f0;
				font-size: 0.9em;
			}
		}
	}

	table {
		width: 100%;

		tr:first-of-type {
			background-color: #e2e6eb !important;
			height: 50px;

			th {
				padding-left: 10px;
			}
		}

		tr:nth-child(odd) {
			background-color: #f5f7f9;
		}

		tr:not(:first-of-type) {
			// background-color: red !important;
			td {
				padding: 10px;
			}
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;