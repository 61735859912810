@count: range(0, 40);
@property: g, m, mx, my, mb, mt, ml, mr, p, px, py, pb, pt, pl, pr, fs, br, lh;

.get-sizes(@item, @value) {
	@g: gap;
	@m: margin;
	@mx: margin-inline;
	@my: margin-block;
	@mb: margin-bottom;
	@mt: margin-top;
	@ml: margin-left;
	@mr: margin-right;
	@p: padding;
	@px: padding-inline;
	@py: padding-block;
	@pb: padding-bottom;
	@pt: padding-top;
	@pl: padding-left;
	@pr: padding-right;
	@fs: font-size;
	@br: border-radius;
	@lh: line-height;
	@prop: @@item;
	@unit: ~'@{value}px';
	.@{item}-@{value} {
		@{prop}: @unit !important;
	}
}

each(@property, {
	.loop-sizes(@value)
});

.loop-sizes(@item) {
	each(@count, {
		.get-sizes(@item, @value)
	});
}

@flex: content, self, items;
@direction: row, row-r, col, col-r;
@display: flex, grid, block, inline, inline-block, none;
@alignment: center, left, right, start, end, between, evenly, around;
.get-alignment(@prop) {
	@center: center;
	@left: left;
	@right: right;
	@start: start;
	@end: end;
	@between: space-between;
	@evenly: space-evenly;
	@around: space-around;
	each(@alignment, {
        @props : @@value;
        &-@{value} {
            @{prop}: @props !important;
        }
    });
}

.get-flex(@item) {
	@j: justify;
	@a: align;
	@dir: @@item;
	each(@flex, {
        @prop: ~"@{dir}-@{value}";
        .@{item}-@{value} {
           .get-alignment(@prop) 
        }
    });
}

.d {
	each(@display, {
        &-@{value} {
            display: @value;
        }
    });
}
.get-flex(j);
.get-flex(a);

each(@direction, {
    @row: row;
    @col: column;
    @row-r: row-reverse;
    @col-r: column-reverse;
    @dir: @@value;
    .@{value}{
        flex-direction: @dir;
    }
});

@weight: range(1, 9);
.get-weights(@value) {
	@finalValue: @value*100;
	.fw-@{value} {
		font-weight: @finalValue !important;
	}
}
each(@count, {
	.get-weights(@value)
});

.t {
	.get-alignment(text-align);
}

@overflow: hidden, scroll, auto;
@axis: ox, oy, o;

.getOverflow(@dir) {
	each(@overflow, {
		&-@{value} {
			@{dir}: @value;
		}
	});
}

each(@axis, {
	 @ox: overflow-x;
	 @oy: overflow-y;
	 @o: overflow;
	 @dir: @@value;
	 .@{value}{
		.getOverflow(@dir) !important;
	 }
});
