.chat-badge-container {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 30px;
	bottom: 30px;
	right: 0px;
	padding-right: 10px;
	z-index: 1000;
	transform: scale(1);
	transition: gap 0.1s ease-in-out, transform 0.3s ease-in-out, right 0.3s ease-in-out;

	&.closed {
		gap: 0px;
	}

	&.small {
		transform: scale(0.7);
		right: -75px;

		.lollipops {
			padding: 10px 30px 15px 30px;

			svg {
				transform: rotate(275deg);
			}
		}
	}

	&.hidden {
		transform: scale(0);
		right: 0px;
	}

	.lollipops {
		transition: padding 0.3s ease-in-out;
		padding: 0px 10px 15px 30px;

		svg {
			transition: transform 0.6s ease-in-out;
			transform: rotate(0deg);
		}
	}

	.chat-assistant-section {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		gap: 15px;
	}

	.chat-assistant-section > * {
		max-width: 35vw;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;