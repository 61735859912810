@height: clamp(100px, calc(90vh - 120px), 600px);
@width: calc(0.64 * @height);

.chat-container {
	height: @height;
	width: @width;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	border-radius: 30px;
	padding: 0px 30px 0px 30px;
	transform: scale(1);
	transition: height 0.3s ease-in-out, width 0.3s ease-in-out, transform 0.6s ease-out;
	background-color: #e8e8e8;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;

	.hide-on-flouting {
		display: none !important;
	}

	&.closed {
		height: 0;
		width: 0;
		padding: 0 40px;
		transform: scale(0);
	}

	.chat-header {
		position: relative;
		width: calc(100% + 60px);
		margin: 0px -30px 0px;
		border-radius: 30px 30px 0 0;
		padding: 30px;
		background-color: #2c64ab;
		color: #ffffff;

		.chat-notch {
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			background-color: #1890ffbd;
			padding: 0px 15px 5px 15px;
			border-radius: 0 0 10px 10px;
			color: #242527;
			z-index: 3;
		}

		.chat-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 20px;

			svg {
				max-width: 30px;
				max-height: 30px;
				cursor: pointer;
			}

			span {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 20px;
			}
		}

		.chat-options {
			position: absolute;
			top: 10px;
			right: 15px;
			display: flex;
			flex-direction: row-reverse;
			gap: 10px;
			font-size: 20px;
			min-width: 20px;
			min-height: 20px;
			transition: all 0.4s ease-in-out;

			.anticon {
				scale: 0;

				&.anticon-close {
					scale: 1;
				}
			}

			.ant-switch {
				scale: 0;
				background-color: #19c37d;
			}

			.ant-switch-checked {
				background-color: #ae69ff;
			}

			&.hover {
				.anticon-close {
					rotate: 360deg;
				}

				.anticon {
					scale: 1;
				}

				.ant-switch {
					scale: 1;
				}
			}
		}
	}

	.chat-body {
		height: 100%;
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
		display: flex;
		flex-direction: column;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 3px;
		}
	}

	.chat-footer {
		width: calc(100% + 60px);
		margin: 0px -30px;
		border-radius: 0 0 30px 30px;
		padding: 30px;
		background-color: #ffffff;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 30px;
		position: relative;

		span {
			font-size: 24px;
		}

		textarea {
			border-radius: 30px;
			background-color: #f2f2f2;
			border: none;
			overflow-y: hidden;
		}

		.anticon-message {
			position: absolute;
			top: 0px;
			transition: top 0.3s ease-in-out, font-size 0.3s ease-in-out;
			font-size: 0px;

			&.loading {
				top: -30px;
				font-size: 24px;
			}
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;