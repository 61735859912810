.tooltip-style-background {
	z-index: 10;
	border-radius: 5px;
	background-color: #fff !important;
	.ant-tooltip-inner {
		min-height: 26px;
		padding: 2px 10px;
		box-shadow: none !important;
	}
}

.tag-color {
	margin: 0 !important;

	& > .ant-typography {
		color: white;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;