@import '../../../../../../shared/styles/variables.less';

.expiring-tickets-modal-name {
	display: flex;
	flex-direction: row;
	align-items: space-between;

	p {
		font-size: 16px;
		font-weight: 500;
	}

	p:nth-child(2) {
		font-weight: normal;
		color: @text-color-secondary;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;