@import '../../../../shared/styles/variables.less';

.denied-stages {
	&__types {
		width: 100%;
		display: flex;
		height: 50px;
		line-height: 50px;
		//cursor: pointer;

		.stages-title {
			color: @links-color;
			border-bottom: 2px solid @links-color;

			> span {
				margin-left: 5px;
				padding: 2px 6px;
				border-radius: 9px;
				color: white;
				background-color: @links-color;
				font-size: 0.9em;
			}
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;