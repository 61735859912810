.contact-form {
	margin-top: 15px;
	border: 1px solid #e0e4eb;
	width: 400px;
	form {
		display: grid;
		padding: 15px;
		> div:not(:nth-of-type(4)):not(:nth-of-type(5)) {
			grid-column-start: 1;
			grid-column-end: 3;
		}
		> div {
			padding: 0 10px;
		}
	}
	&__footer {
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 55px;
		background: #eff1f5;
	}
}

.contacts-cards {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
}

.account-settings-contacts {
	h3 {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 5px;
	}

	> form {
		margin-top: 20px;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;