.chat-container {
	.chat-message {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		padding: 10px;
		margin: 5px 0;
		max-width: 80%;
		width: auto;
		background-color: #f2f2f2;
		position: relative;

		&.user {
			align-self: flex-end;
			background-color: #b7cce7;

			.anticon {
				left: -15px;
				right: auto;
			}
		}

		&.info {
			align-self: center;
			background-color: #188fff17;
		}

		&.failed {
			background-color: #f2b7b7;
		}

		&.loading {
			opacity: 0.5;
		}

		.anticon {
			position: absolute;
			right: -15px;
			bottom: 0px;
		}

		.anticon-bug {
			top: 0px;
			bottom: auto;
			cursor: pointer;
		}

		pre {
			background-color: #242527;
			color: #fff;
		}

		.ant-list-item {
			border-bottom: 1px solid #d6d6d6;

			&:hover {
				background-color: #b7cce7;
				cursor: pointer;
			}
		}

		.ant-list-header {
			border-bottom: 1px solid #9a9a9a;
			background-color: #188fff17;
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;