.copilot-actions {
	position: relative;

	.copilot-actions-container {
		position: absolute;
		top: 100%;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		align-items: flex-start;
	}

	.ant-card-body {
		padding: 5px;
	}

	.anticon-experiment {
		font-size: 24px;
		transition: font-size 1s ease-in-out;

		&.closed {
			animation: zoomIn 10s infinite ease-in-out 2s;
		}

		&.added {
			path:nth-child(2) {
				animation: spotlight 1.5s 4 ease-in-out;
			}

			path:nth-child(1) {
				animation: spotlight-darker 3s 2 ease-in-out;
			}

			path:nth-child(3) {
				animation: spotlight-lighter 3s 2 ease-in-out;
			}
		}
	}

	&.closed {
		.copilot-actions-container {
			display: none;
		}
	}

	&.no-actions {
		display: none;
	}

	.ant-card {
		transition: scale 1s ease-in-out;
	}
}

@keyframes spotlight {
	0% {
	}

	50% {
		fill: green;
	}

	100% {
	}
}

@keyframes spotlight-lighter {
	0% {
	}

	50% {
		fill: rgb(214, 186, 215);
	}

	100% {
	}
}

@keyframes spotlight-darker {
	0% {
	}

	50% {
		fill: orangered;
	}

	100% {
	}
}

@keyframes zoomIn {
	0% {
		scale: 1;
	}

	5% {
		scale: 1.5;
	}

	10% {
		scale: 1;
	}

	96% {
		scale: 1;
	}

	98% {
		scale: 1.5;
	}

	100% {
		scale: 1;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;