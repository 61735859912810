@import '../../shared/styles/variables.less';

.lt-lg (@rules) {
	@media screen and (max-width: 1440px) {
		@rules();
	}
}
.md-lg (@rules) {
	@media screen and (min-width: 1201px) and (max-width: 1440px) {
		@rules();
	}
}
.lt-md (@rules) {
	@media screen and (max-width: 1200px) {
		@rules();
	}
}
.login input:-webkit-autofill,
.login input:-webkit-autofill:hover,
.login input:-webkit-autofill:focus,
.login input:-webkit-autofill:active {
	-webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
	-webkit-transition-delay: 9999s;
}

.login {
	height: 100%;
	display: flex;
	font-size: 13px;
	flex-direction: row;
	&__side,
	&__overview {
		width: 50%;
		height: 100%;
		display: flex;
		color: @color-white;
		padding: 70px 120px;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		transition: padding 0.8s;
		.lt-lg({
			padding: 70px 10px;
		});
		.lt-md({
			width: 100%;
		});
	}
	&__side {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('../../assets/images/login/elipse_bg.svg');
		* {
			line-height: normal;
		}
		.md-lg ({
			width: 60%;
		});;
		&__logo {
			width: 220px;
			display: flex;
			align-items: center;
			flex-direction: row;
			margin: 0 auto 4rem auto;
			justify-content: space-between;
			&__icon,
			&__title {
				width: 73px;
				height: 49px;
				background-size: 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url('../../assets/images/login/arla_symbol.svg');
			}
			&__title {
				width: 134px;
				background-image: url('../../assets/images/login/arla_no_symbol_white.svg');
			}
		}
		&__form {
			gap: 24px;
			width: 70%;
			display: flex;
			min-width: 240px;
			flex-direction: column;
			.ant-form-item,
			input {
				margin: 0;
			}
			.ant-input-affix-wrapper,
			.ant-form-item-required,
			.ant-checkbox-inner,
			.ant-input,
			.white,
			label,
			a {
				background: none !important;
				color: @color-white;
			}
			.ant-input {
				padding: 8px 16px;
			}
			.ant-input-affix-wrapper,
			.ant-form-item-label {
				padding: 0;
				.ant-input {
					border-radius: 0;
					padding: 8px 16px;
				}
				.ant-input-suffix {
					.anticon {
						color: #b3b3b3;
					}
					top: 10px;
					right: 16px;
					position: absolute;
				}
			}
			.ant-input::placeholder {
				color: #b3b3b3;
			}
			&__title {
				color: @color-white;
				font-size: 2rem;
			}
			&__inputs__invalid {
				color: @red-color-light;
			}
			.ant-form-item-control {
				display: flex;
				flex-direction: column;
				gap: 5px;

				.ant-input-status-error,
				.ant-input-affix-wrapper-status-error {
					border-color: @red-color-light !important;
				}
			}
			&__bottom {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.ant-btn-primary {
				background: #2d64ab;
				border-color: #2d64ab;
				&:hover {
					background: #a7ccfc;
					border-color: #a7ccfc;
				}
			}
		}
	}
	&__overview {
		color: @active-color;
		.lt-md ({
			display: none;
		});;
		&__wrapper {
			gap: 38px;
			width: 100%;
			display: flex;
			flex-direction: column;
			.ant-carousel {
				box-shadow: 0px 18px 25px -3px rgba(0, 0, 0, 0.15);
				.slick-dots {
					bottom: -33px;
					li {
						width: 10%;
					}
					button {
						background-color: #cccccc;
						border-radius: 3px;
						width: 100%;
					}
					.slick-active {
						width: 20%;
						button {
							background-color: #7fabe3;
						}
					}
				}
			}
			&__title {
				font-size: 2rem;
				line-height: 2rem;
			}
		}
		&__image {
			width: 100%;
			aspect-ratio: 27/17;
			background-size: contain;
			background-repeat: no-repeat;
			&__1 {
				background-image: url('../../assets/images/login/overview_1.png');
			}
		}
	}
}
