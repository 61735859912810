@import '../../shared/styles/variables.less';
.nav {
	position: absolute;
	display: flex;
	background-color: @menu-color;
	align-items: center;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 2;

	.settings-icon {
		color: #fff;
		font-size: 20px;
		margin-right: 12px;
		vertical-align: top;
		height: 100%;
	}

	.support-link {
		margin: 0 15px;
		background-color: inherit;
		color: @color-white;
	}

	&__logo {
		background-size: cover;
		width: 35px;
		height: 35px;
		background-image: url('../../assets/images/AIVA-logo.png');
		margin: 0 20px;
	}
	&__menu {
		width: 55%;
		.ant-menu,
		.ant-menu-dark {
			background-color: inherit !important;
			color: @color-white !important;
			.ant-menu-item > a,
			.ant-menu-item-only-child > a {
				color: @color-white;
				display: inline-block;
				text-transform: capitalize;
			}
		}
		.ant-menu-submenu {
			.ant-menu-item > a,
			.ant-menu-item-only-child > a {
				color: @color-white;
				display: inline-block;
				text-transform: capitalize;
			}
		}
		.ant-menu.ant-menu-dark .ant-menu-item-selected {
			font-weight: 600;
			background-color: @active-color;
			&:hover {
				background-color: @active-color;
			}
		}
		//.ant-menu-item {
		//	::after {
		//		display: block;
		//		content: attr(data-text);
		//		font-weight: bold;
		//		//height: 0;
		//		overflow: hidden;
		//		visibility: hidden;
		//		//margin-top: -18px;
		//	}
		//}
		.ant-menu.ant-menu-dark .ant-menu-item-active {
			&:hover {
				background-color: @active-color;
			}
		}
	}
	&__user-dropdown {
		position: absolute;
		right: 0;
		margin-right: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		&__menu {
			position: absolute;
			top: 46px;
			right: 0px;
		}
	}

	.theme-selector {
		display: flex;
		align-items: center;
		gap: 1rem;
		color: white;
	}

	.dark-mode-switch {
		svg {
			margin-top: 5px;
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;