.copilot-progress-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;

	.ant-progress-bg {
		transition: all 1s ease-in;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;