@import '../../../shared/styles/variables.less';

.company-information {
	.satellite-offices__header {
		display: flex;
		gap: 20px;
	}

	h3 {
		font-size: 18px;
		font-weight: 500;
		color: @text-color;
	}
	> div:first-of-type {
		> span {
			font-size: 14px;
		}
	}
	.ant-form {
		display: flex;
		margin-bottom: 30px;
	}

	&__table {
		display: flex;
		margin-top: 15px;
		.editable-table {
			display: grid;
			grid-template-columns: 1fr 1fr;
			width: 768px;
			> div {
				> * {
					padding: 7px;
					width: 50%;
				}
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				height: 32px;
			}
		}
	}
	.separator {
		width: 95%;
		height: 1px;
		left: 50%;
		transform: translateX(-50%);
		border-bottom: 1px solid #e0e4eb;
		margin-bottom: 20px;
	}

	.billing-address-form {
		&-footer {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			height: 56px;
			background: #eff1f5;
		}
		form {
			.form-item-5,
			.form-item-6,
			.form-item-7 {
				grid-row: 3;
			}

			padding: 20px 15px;
			> div {
				padding: 10px;
			}
			display: grid;
			grid-template-columns: 330px 220px 250px;
			grid-template-rows: repeat(3, 90px);
		}
		place-items: center;
		border: 1px solid #e0e4eb;
	}

	.satellite-offices {
		padding: 20px 0;
		> form {
			display: flex;
			flex-wrap: wrap;
			gap: 30px;
			> .ant-form-item {
				margin-bottom: 0;
			}
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;