.layout {
	height: 100%;
	width: 100%;
}

.layout-content {
	background: #fff;
	margin-top: 46px;
	padding: 0px;
	height: 100%;
	width: 100%;
}

@error-color: #f5222d;@border-radius-base: 4px;