@import '../../styles/variables.less';

.ant-popover {
	position: fixed;
}

.progress-notification {
	width: 60px;
	height: 46px;
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: center;

	&:hover {
		background-color: #123062;
		cursor: pointer;
	}

	&__popover {
		.ant-popover-inner-content {
			padding: 20px !important;
		}
		&__content {
			width: 384px;
			h2 {
				font-size: 18px;
				font-weight: 600;
			}
			h3 {
				margin-top: 5px;
				font-size: 16px;
				font-weight: 400;
				color: rgba(0, 0, 0, 0.65);
			}
			span {
				color: rgba(0, 0, 0, 0.65);
				font-size: 14px;
			}
			button {
				padding: 0 8px;
				height: 24px;
				font-size: 14px;
				line-height: 22px;
				position: relative;
				left: 280px;
			}
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;