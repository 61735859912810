.settings-form-non-editable {
	border: 1px solid #e0e4eb;
	width: 400px;
	> div:first-of-type {
		background-color: #e0e4eb;
		align-items: center;
		padding: 15px;
		display: flex;
		justify-content: space-between;
	}

	> div:nth-child(2) {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 20px;
		div {
			display: flex;
			align-items: center;
			span {
				width: 35%;
			}
		}
	}
}

.editable-settings-modal {
	.ant-modal-body {
		padding: 0;
	}
}

.satellite-office-editable {
	border: 1px solid #e0e4eb;
	display: grid;
	width: 400px;

	&-modal {
		width: 100%;
		border: none !important;
	}

	form {
		padding: 15px;
		> div {
			padding: 0 15px;
		}

		display: grid !important;
		// grid-template-columns: 2fr 3fr;
		grid-template-rows: repeat(1fr, 5);
	}

	&-office {
		form {
			> div:not(:nth-of-type(4)):not(:nth-of-type(5)) {
				grid-column-end: 3;
				grid-column-start: 1;
			}
			grid-template-columns: 3fr 2fr;
		}
	}

	&-contact {
		form {
			> div:not(:nth-of-type(5)):not(:nth-of-type(6)) {
				grid-column-end: 3;
				grid-column-start: 1;
			}
			grid-template-columns: 2fr 3fr;
		}
	}

	&__footer {
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 55px;
		background: #eff1f5;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;