@import '../Login/login.less';

.login-back {
	gap: 8px;
	display: flex;
	font-size: 16px;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	span {
		line-height: 0;
		font-size: 20px;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;