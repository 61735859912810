@import '../../shared/styles/variables.less';

.account-settings {
	h3 {
		font-size: 20px;
		color: @text-color;
		font-weight: 500;
	}
	&__header {
		background: #fafafa;
		padding: 30px 30px;
		span {
			font-size: 14px;
			color: #565758;
		}
	}
	&__tabs {
		padding: 0 30px;

		.ant-tabs-nav-wrap {
			background: #fafafa;
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;