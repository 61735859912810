.label {
	display: inline-block;
	width: 200px;
}

.value {
	font-weight: bold;
}

.group,
.input-group {
	padding: 3px 0;
}

@error-color: #f5222d;@border-radius-base: 4px;