@import '../../../../shared/styles/variables.less';

.info-modal-list {
	&__title {
		background: #eef7ff;
	}
	&__types {
		width: 100%;
		height: 50px;
		display: flex;
		cursor: pointer;
		line-height: 50px;
		> div {
			flex: 1 100%;
			text-align: center;
			> span {
				margin-left: 5px;
				padding: 2px 8px;
				border-radius: 20px;
				background-color: #f0f0f0;
				font-size: 12px;
			}
		}
		.active-type {
			color: @links-color;
			border-bottom: 2px solid @links-color;
			> span {
				color: white;
				background-color: @links-color;
			}
		}
	}

	table {
		width: 100%;
		display: flex;
		flex-direction: column;

		thead tr {
			height: 50px;
			background-color: #e2e6eb !important;
			th {
				justify-content: center;
			}
		}

		tbody {
			overflow-y: auto;
			max-height: 500px;
			border: 1px solid #e2e6eb !important;
		}

		tr {
			display: flex;
			&:nth-child(odd) {
				background-color: #f5f7f9;
			}
		}

		td,
		th {
			display: flex;
			flex-direction: column;
			flex: 1 1 100%;
		}

		td {
			padding: 10px;
			word-break: break-all;
			&:not(:last-child) {
				border-right: 1px solid #e2e6eb !important;
			}
		}
	}
}

@error-color: #f5222d;@border-radius-base: 4px;