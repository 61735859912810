.chat-assistant-message-debugger {
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	transition: width 0.6s ease-in-out height 0.6s ease-in-out;

	.ant-card-body {
		padding: 10px;
	}

	.ant-tabs-tabpane-active {
		padding: 10px;
		display: flex;
		flex-direction: column;

		max-height: 55vh;
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;

		.ant-btn {
			align-self: center;
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 3px;
		}

		.ant-list {
			min-width: 400px;

			.ant-card {
				width: 100%;
			}
		}
	}

	.chat-icon {
		max-width: 15px;
	}
}

@error-color: #f5222d;@border-radius-base: 4px;